/* global I18n, mathVirtualKeyboard */

import 'core-js/stable';

import '@vendor/i18n';

import '@vendor/appsignal';
import './behaviours';
import './quiz/behaviours';
import './grading/behaviours';
import './channels/quiz-participants';
import './channels/quiz-responses';
import './channels/results/chat';
import './channels/dashboard';
import './channels/assignments/chats';
import './channels/assignments/sync';
import './channels/preliminary-result';
import './channels/taking/start-assignment';

// Stylesheets
import './stylesheets/application.scss';

import storage from 'local-storage-fallback';

import Rails from '@rails/ujs';
import '@modules/rails-confirm';

import * as ActiveStorage from '@rails/activestorage';

import onmount from 'onmount';

import LazyLoad from 'vanilla-lazyload';

import '@redactor/intialization';

import mdcAutoInit from '@material/auto-init';
import '@components/auto-init';

import snackbar from '@components/snackbar';

import './controllers';
import { Turbo } from '@hotwired/turbo-rails';

window.webpackOnmount = onmount;

Rails.linkDisableSelector += ', li[data-disable-with]';
window.Rails = Rails;
Rails.start();
ActiveStorage.start();

if (!('localStorage' in window)) window.localStorage = storage;

function initializeComponents() {
  window.myLazyLoad = new LazyLoad({ threshold: 1000 });
  mdcAutoInit();
  onmount();
}

function initializeTurbo() {
  document.addEventListener('turbo:load', () => {
    document.body.style.pointerEvents = 'auto';

    try {
      if (typeof mathVirtualKeyboard === 'object') mathVirtualKeyboard.container = document.body;
    } catch (err) {
      console.warn(err); // eslint-disable-line no-console
    }

    if (document.querySelector('[data-js-redactor], [data-js-grade-formula], [data-js-flatpickr]')) {
      Turbo.cache.exemptPageFromCache(); // Exclude pages with redactor element on the page from being cached
    }

    initializeComponents();
  });

  document.addEventListener('turbo:frame-load', () => {
    document.body.style.pointerEvents = 'auto';
    initializeComponents();
  });

  document.addEventListener('turbo:click', (e) => {
    if (e.target.getAttribute('href') === '#!') e.preventDefault();
  });
}

document.addEventListener('DOMContentLoaded', () => {
  document.body.style.pointerEvents = 'auto';
  initializeComponents();
  initializeTurbo();

  document.addEventListener('ajax:complete', () => {
    setTimeout(() => {
      console.log('ajax:complete'); // eslint-disable-line
      mdcAutoInit();
      onmount();
    }, 2);
  });

  document.addEventListener('ajax:error', (data) => {
    const [, , xhr] = data.detail;
    if (xhr.status !== 401) return;

    snackbar(I18n.t('js.general.session_timedout'), true);
    setTimeout(() => {
      window.location.reload();
    }, 5 * 1000);
  });
});
