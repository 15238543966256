import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="select-button"
export default class extends Controller {
  static classes = ['active'];

  static targets = ['button'];

  select(e) {
    if (e.currentTarget.classList.contains(this.activeClass)) return;

    this.deselectAll();

    e.currentTarget.classList.add(this.activeClass);
  }

  deselectAll() {
    this.buttonTargets.forEach((el) => el.classList.remove(this.activeClass));
  }
}
