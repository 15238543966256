import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'panel'];

  openPanel() {
    this.buttonTarget.classList.add('handle-button-active');
    this.panelTarget.classList.remove('d-none');

    setTimeout(() => {
      this.panelTarget.style.minWidth = '400px';
    }, 1);

    this.buttonTarget.dataset.action = 'click->annotations#closePanel';
  }

  closePanel() {
    this.buttonTarget.classList.remove('handle-button-active');
    this.panelTarget.style.minWidth = '0px';

    setTimeout(() => {
      this.panelTarget.classList.add('d-none');
    }, 300);

    this.buttonTarget.dataset.action = 'click->annotations#openPanel';
  }
}
