import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  changed(e) {
    e.detail.setAttribute('data-unsaved-changes-warning-target', 'item');
  }

  unmountAttributes(e) {
    if (!e.params) return;

    const { attributes } = e.params;
    const attrs = attributes.split(' ');

    attrs.forEach((attr) => {
      e.detail.querySelectorAll(`[${attr}]`).forEach((el) => el.removeAttribute(attr));
    });
  }
}
