import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleButton', 'feedbackField', 'feedback', 'clearInput'];

  connect() {
    if (this.feedbackTargets.filter((field) => field.value || field.checked).length) {
      this.toggleButtonTarget.checked = true;
    } else {
      this.toggleButtonTarget.checked = false;
    }

    this.toggleFeedbackField();
  }

  toggleFeedbackField() {
    if (this.toggleButtonTarget.checked) {
      this.showFeedbackField();
    } else {
      this.hideFeedbackField();
    }
  }

  showFeedbackField() {
    this.feedbackFieldTargets.forEach((feedbackField) => {
      feedbackField.classList.remove('d-none');
    });
  }

  hideFeedbackField() {
    this.feedbackFieldTargets.forEach((feedbackField) => {
      feedbackField.classList.add('d-none');
    });
    this.clearValueOnHide();
  }

  clearValueOnHide() {
    this.clearInputTargets.forEach((inputField) => {
      inputField.value = null;
    });
  }
}
