import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { createFocusTrap } from 'focus-trap';

// Connects to data-controller="side-panel"
export default class extends Controller {
  static targets = ['panel', 'scrim'];

  connect() {
    if (this.hasPanelTarget) {
      useClickOutside(this, { element: this.panelTarget });
      this.initializeFocusTrap();
      document.addEventListener('keydown', this.handleKeyDown.bind(this));
    }
  }

  panelTargetConnected(element) {
    useClickOutside(this, { element });
    this.initializeFocusTrap();
  }

  initializeFocusTrap() {
    this.focusTrap = createFocusTrap(this.panelTarget, {});
  }

  open() {
    document.body.dataset.hotkeysDisabled = true;
    this.showScrim();
    this.expandPanel();
    this.focusTrap.activate();
  }

  close() {
    delete document.body.dataset.hotkeysDisabled;
    this.hideScrim();
    this.collapsePanel();
    this.focusTrap.deactivate();
  }

  showScrim() {
    this.scrimTarget.style.display = 'block';
  }

  hideScrim() {
    this.scrimTarget.style.display = 'none';
  }

  expandPanel() {
    this.panelTarget.style.width = '600px';
  }

  collapsePanel() {
    this.panelTarget.style.width = '0';
  }

  clickOutside() {
    this.close();
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
}
