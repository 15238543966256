import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['updateChoicesPoints'];

  static targets = ['points', 'maximumPoints', 'selected'];

  connect() {
    useDebounce(this, { wait: 250 });
  }

  validateChoices({ detail: { value } }) {
    if (!this.hasPointsTarget) return;
    if (value !== 'automatic_scoring') return;

    this.automaticScoringValues();
  }

  automaticScoringValues() {
    let highestPoints = 0;

    this.pointsTargets.forEach((input, index) => {
      const value = parseFloat(input.value);
      if (value > highestPoints) {
        highestPoints = value;
        this.selectedTargets[index].checked = true;
      } else {
        input.numeric.setValue(0);
        this.selectedTargets[index].checked = false;
      }
    });
  }

  updatePoints({ detail: { element } }) {
    if (!parseFloat(this.maximumPointsTarget.value)) return;

    this.pointsTargets.forEach((el) => el.numeric.setValue((el === element) ? this.maximumPointsTarget.value : 0));
  }

  updateSelected() {
    const maxPointField = this.highestPointsInput()[0];
    if (!maxPointField) return;

    if (maxPointField.value === '0') this.deselectAll();

    this.maximumPointsTarget.numeric.setValue(maxPointField.value);
  }

  deselectAll() {
    this.selectedTargets.forEach((el) => { el.checked = false; });
  }

  updateChoicesPoints() {
    if (parseFloat(this.maximumPointsTarget.value)) {
      this.highestPointsInput().forEach((el) => el.numeric.setValue(this.maximumPointsTarget.value));
    }
  }

  highestPointsInput() {
    const maxPoints = Math.max(...this.pointsTargets.map((el) => (!el.value ? 0 : parseFloat(el.value))));

    return this.pointsTargets.filter((el) => parseFloat(el.value) === maxPoints);
  }
}
